@import 'common-flagship_tasting_room';

//stylelint-disable
.pswp__img {
	background: $color-product_image-background;
}

.b-dialog-btn{
	&.m-alt {
		border:none;
		@media (hover: hover) and (pointer: fine) {
			&:hover {
				background: var(--button_alt-background_color, $color-button_alt-background);
				color: var(--button_alt-text_color, $color-button_alt-text);
			}
		}
		.b-dialog-btn_text {
			@include t-link_underlined($state: default, $underline-offset: 5px, $font-weight: inherit);
			@include t-link_underlined($state: hover);
			letter-spacing: inherit;
		}
	}
}


.b-product_tile {
	font-family: $font-alt;
	&-name{
		font-family: $font-alt;
	}
}

.b-product_quantity-input {
	background: transparent;
	&:disabled {
		background: transparent;
	}
}


.b-header {
	background-color: $color-shade_1;
	color: $color-primary;
	border-bottom: 1px solid $color-shade_4;
	font-size: 16px;
	&-background{
		border: 0;
	}
	&:not(.m-active) {
		height: auto;
	}
	&-navigation_inner {
		background-color: $color-shade_1;
	}
	&.m-stuck{
		background-color: $color-shade_1;
		color: $color-primary;
	}
	&.m-transparent {
		&:not(.m-inverted) {
			&:not(.m-active) {
				&:not(.m-stuck) {
					&:not(.m-hamburger_opened) {
						background-color: $color-shade_1;
						color: $color-primary;
					}
				}
			}
		}
	}
}

.b-age_gate{
	&-inner {
		color: $color-primary;
	}
	&-button {
		color: $color-primary;
		border-color: $color-primary;

		&:hover {
			background-color: $color-primary;
			color: $color-shade_1;
		}
	}
}

.b-categories_navigation{
	@include media(md-up) {
		&-list_1 {
			padding: 18px 0 0;
		}
	}

	&-item_1 {
		font-family: $font-main-semiBold;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: 1px;

		&:hover {
			color: $color-shade_10;

			.b-categories_navigation-group_1 {
				background-color: $color-shade_1;
				color: $color-primary;
			}
		}
		&:after {
			border-bottom: 3px solid $color-shade_10;
		}
	}
	&-group_1{
		border-top: 1px solid $color-secondary;
		&[aria-hidden="false"]{
			background-color: $color-shade_1;
			color: $color-primary;
		}
	}
	&-group_2{
		background-color: $color-shade_1;
	}
	&-link_1 {
		font-family: $font-main-semiBold;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: 1px;

		@include media(md-up) {
			padding: 0 0 24px;
		}

		@include media(md) {
			padding: 25px 10px !important;
		}
	}
}

.b-carousel {
	&-control{
		border: 0;
	}
	&-pagination_control{
		border: 1px solid $color-primary;
	}

	&-title_custom{
		font-size: 2rem;
	}
}


.b-shipping_panel-inner{
	background-color: $color-shade_1;
}

.b-breadcrumbs {
    &-item {
		align-items: baseline;
		&:not(.m-current) {
			@include media(md-up) {
				@include icon('arrow-right', 8px, 8px, after) {
					background: $color-breadcrumb-separator_bg;
					margin: 2px 15px 0;
				}
			}
		}
	}

	&-link {
		color: $color-secondary;
		text-transform: capitalize;

		@include media(sm) {
			color: $color-shade_3;
			font-size: 16px;
			line-height: 24px;
			letter-spacing: 0.6px;
			font-weight: 400;
		}
	}
}

// #Brand logo responsive #
.b-logo-image{
	@include media(md-up) {
		position: static;
	}

	&.logo {
		&-desktop {
			@include media(sm){
				display: none;

			}
		}
		&-mobile {
			display: none;
			margin-top: -12%;
			margin-left: -12%;
			@include media(sm){
				display: block;

			}
		}
	}
}

.b-logo-wrapper .b-logo-image img {
    max-width: 46%;

	@include media(sm){
		max-width: 100%;
	}
}

.b-logo-wrapper {
	padding-bottom: 0;
}

.b-header-navigation{
	@include media(md-up) {
		padding-top: 12px;
	}

	@include media(md){
		padding-top: 0 !important;
	}

	@include media(sm){
		padding-top: 0;
	}
}

//favorite text hide on scroll
.b-header{
    &.m-stuck{
        .b-header-utility_item{
            &.m-favorites {
                font-size: 0;
            }
        }

		@include media(md-up) {
			.b-categories_navigation-list_1 {
				padding-top: 12px;
			}
		}
    }
}

.b-footer_social-links {
	// justify-content: flex-start;
	a {
		&:not(:first-child) {
			padding: 0px 16px;
		}
	}
}

.b-product_tile {
	&.m-grid{
		.b-product_quantity {
			border-right: 1px solid $color-quantity;
			margin-bottom: 5px;
			&.m-disabled {
				border-color: rgba($color-quantity,$configuration-product_quantity-disabled-opacity);
			}
			@include media(md-up) {
				margin-bottom: 0px;
				margin-right: 5px;
				border-right: 1px solid $color-quantity;
			}
		}
	}
}


.b-carousel {
	.b-product_tile-actions {
		display: flex;
		@include media(sm) {
		display: block;
		}
	}
}

.b-header{
	&-utility_item {
		&.m-favorites:after,&.m-favorites_active:after{
			display: none;
		}
		&.m-favorites {
			@include icon(heart, $icon-width: 17px, $icon-height: 16px, $position: before);
			padding: 15px;
			&:before{
                margin-right: 10px;
            }

			@include media(sm) {
				padding: 10px;
			}
		}

		&.m-favorites_active {
			@include icon(wishlist-active, $icon-width: 17px, $icon-height: 16px, $position: before) {
				background: $color-accent;
			}
		}
	}
}

.b-footer{
	border-color: $color-shade_4;

	&_navigation-title, &-newsletter_title, &_info-title, &_social-title {
		@include t-heading_6;
		color: $color-shade_9;
		text-transform: uppercase;
	}

	&_navigation-link, &_navigation-content, &_info-link, &_info-text, &_info-link.m-info {
		color: $color-secondary;

		@include media(sm) {
			font-weight: 400;
			color: $color-secondary !important;
		}
	}
}

.b-header{
	.b-header_login-title_link{
		&:before{
			min-width: 16px;
		}
		@include media(lg-up){
			min-width: 138px;
			padding: 15px;
		}
	}
	&.m-stuck{
		.b-header_login-title_link {
			min-width: 0px;
		}
	}

	
	.b-minicart_popup-button, .b-minicart_popup-link {
		align-items: center;

		@include media(lg-up) {
			padding: 18px 15px;
		}
	}

	.b-minicart_popup-qty {
		line-height: 16px;
	}
	
}

.b-minicart_product-remove{
	.b-button_remove{
		@include icon('close', 16px, 16px, before)
	}
}

.b-cart_product-remove{
	.b-button_remove{
		@include icon('close', 16px, 16px, before)
	}
}

.b-product_badge {
	&.m-promo {
		border: $configuration-product_badge-border_radius solid $color-promo-background;
		border-radius: 13px;
		width: fit-content;

		@include media(md-down){
			width: fit-content;
			padding: 0 5px;
		}
	}
}

.b-ship_to-link:after {
	width: 8px;
	height: 8px;
}

.b-ship_to-state{
	border-bottom: 1px solid currentColor;
}

.b-product_badge {
	color: $color-secondary !important;

	&.m-promo {
		background: $color-promo-background;
		color: $color-secondary !important;
		line-height: 18px;
		letter-spacing: 0.4px;
	}
	
	&-image {
		background: none !important;
		border: none;
	}
	&.m-rating_points {
		background-image: url('./svg-icons/badge-designlogo.svg');
		border-radius: 50%;
	}
	&-abbr, &-text {
		font-size: 16px;
		letter-spacing: 0.15px;
		line-height: 24px;

		@include media(md-down) {
			font-size: 12px;
			letter-spacing: 0.12px;
			line-height: 14px;
		}
	}

	&-points {
		font-size: 32px !important;
		letter-spacing: 0.31px;

		@include media(md-down) {
			font-size: 20px !important;
			letter-spacing: 0.19px;
		}
	}
}

.b-footer {
	&-social {
		margin-bottom: 20px;
	}
}
.b-footer_social-links {
	justify-content: flex-start !important;
	@include media(md-down) {
		justify-content: center !important;
	}
}

.b-age_gate-border{
	border:7px solid rgba($color-primary,0.6);
	margin: 30px;
	padding-bottom: 30px;
	overflow: hidden;
	@include media(sm){
		margin: 15px;
		padding-bottom: 20px;
	}
}

.b-error_page {
	grid-auto-rows:auto !important;
	padding: 0 !important;
	&_outer-border {
		@extend .b-age_gate-border;
		padding: 0 !important;
	}
	&_inner-border {
		position: relative;
		margin: 15px;
		border: 3px solid rgba($color-primary,0.6);
	}
	&-title {
		font-size: 48px !important;
    	font-weight: 600 !important;
		@include media(sm){
			font-size: 40px !important;
		}
	}
	&-text {
		max-width: fit-content;
	}
	&-btn {
		border-color: $color-primary;
		margin-bottom: 60px;
		@include media(sm){
			margin-bottom: 50px;
		}
	}
}

@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) {
    .f-input_date-field {
		font-variant-numeric: diagonal-fractions !important;
		font-size: 20px !important;
		letter-spacing: 5px !important;
	}
}}

.b-newsletters {
	.f-input_text-field {
		border-radius: 0;
	}
}

.b-product_tile-cta, .b-product_tile .b-product_quantity, .b-product_quantity {
	height: 40px;
	border: 1px solid $color-shade_5;
	border-radius: 20px;
}

.b-order_summary {
	border-radius: 20px;
}

.b-header_promo {
	background: $color-promo-background;
}

.b-promo_line-inner {
	padding: 0 16px;
}
.b-promo_line-inner, .b-header_promo-content {
	font-size: 16px;
	line-height: 24px;
	color: $color-shade_3;

	a {
		color: $color-secondary;
		text-decoration: underline;
	}
}

@include media(md-up) {
	.b-header-links {
		align-items: flex-start;
		margin-top: 16px;
	}
}

.b-header.m-stuck .b-header-utility {
	align-items: center;
}

.b-header-utility {
	margin-top: 16px;

	@include media(md-up) {
		align-items: flex-start;
	}

	@include media(md) {
		align-items: center;
		margin-top: 0;
		padding-top: 18px;
		padding-bottom: 12px;
	}
}


.b-header-links_item.m-stores, .b-header-utility_item.m-search {
	color: $color-shade_3;

	@include media(md-up) {
		padding: 15px;
		align-items: center;
	}

	@include media(md) {
		align-items: center;
		padding: 8px 15px 15px !important;
	}
}

// .b-header-close_hamburger {
// 	@include media(md) {
// 		top: -80px;
// 	}
// }

.b-header-hamburger_cta {
	@include media(md) {
		padding-top: 12px;
	}
}

.b-product_tile-name {
	font: 400 20px/32px $font-alt;
	letter-spacing: 0.6px;
	color: $color-secondary;
	text-transform: capitalize;

	.b-product_tile-link {
		height: 64px;
		line-height: 32px;
	}
}

.b-footer-copyright {
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.4px;
	color: $color-shade_5;
}

.b-footer_legal-link {
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.4px;
	color: $color-secondary;
}

.b-back_to_top {
	&-copy {
		color: $color-shade_5;
	}
}

.b-header-logo {
	align-items: flex-start;

	@include media(md-up) {
		margin-top: 16px;
	}

	@include media(md) {
		padding-top: 8px;
		margin-left: 16px;
		margin-top: 0 !important;
	}

	@include media(sm) {
		margin-left: 8px;
		padding-top: 6px;
		margin-top: 0 !important;

		.b-logo {
			max-width: 300px;
	
			& img {
				width: 80px;
				height: auto;
			}
		}
	}
}

.b-customer_service_info.m-small {
	.b-customer_service_info-title {
		color: $color-shade_5;
	}

	.b-customer_service_info-item {
		color: $color-secondary;

		.b-customer_service_info-link, span {
			color: $color-secondary;
		}
	}
}

h1, .b-text_box h1 {
	@include t-heading_1;

	&.t-heading_1 {
		@include t-heading_1;
	}

	&.t-heading_1-2 {
		@include t-heading_1-2;
	}
}

h2, .b-text_box h2 {
	@include t-heading_2;
}

h3, .b-text_box h3 {
	@include t-heading_3;
}

h4, .b-text_box h4 {
	@include t-heading_4;
}

h5, .b-text_box h5 {
	@include t-heading_5;
}

h6, .b-text_box h6 {
	@include t-heading_6;
}

.b-cart_product {
	.b-cart_product-total, .b-cart_product-remove {
		@include media(lg-up) {
			align-items: flex-start;
			margin-top: 12px;
		}
	}
}

.b-header-links_item {
	&.m-stores {
		&::before {
			position: relative;
			bottom: 2px;
		}
	}
}

.b-header-navigation_link.m-stores{

	text-transform:uppercase;
}
// Hide rebate option on contact form
option[value="Rebate Status"] {
    display: none;
	pointer-events: none;
}