/*md

# Headings components

```scss_example
.b-title {
	@include t-heading_1;
}
```

*/

@mixin t-heading_1 {
	font: $configuration-t_heading_1-font;
	color: $configuration-t_heading_1-color; 
	letter-spacing: 0.2px;

	@content;
}

@mixin t-heading_1-2 {
	font: $configuration-t_heading_1-2-font;
	color: $configuration-t_heading_1-2-color; 

	@content;
}

@mixin t-heading_2 {
	font: $configuration-t_heading_2-font;
	letter-spacing: 0.2px;
	color: $configuration-t_heading_2-color;

	@content;
}

@mixin t-heading_3 {
	font: $configuration-t_heading_3-font;
	letter-spacing: 0.2px;
	color: $configuration-t_heading_3-color;

	@content;
}

@mixin t-heading_4 {
	font: $configuration-t_heading_4-font;
	letter-spacing: 0.2px;
	color: $configuration-t_heading_4-color;

	@content;
}

@mixin t-heading_5 {
	font: $configuration-t_heading_5-font;
	letter-spacing: 0.2px;
	color: $configuration-t_heading_5-color;

	@content;
}

@mixin t-heading_6 {
	font: $configuration-t_heading_6-font;
	letter-spacing: 0.7px;
	color: $configuration-t_heading_6-color;

	@content;
}
