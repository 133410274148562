// # Applied colors palette #
//============================================
// ## Main colors ##
$color-primary:  #F7F1C6; //$color-shade_1;
$color-secondary: $color-primary;//added
$color-tertiary: #F9423A;
$color-primary-background: #3C2528;//#234121;
$color-error: #CA1E36;//#D32400;
$color-error-background:#faeded;//#341616;
$color-success: #009418;// #37a10a;
$color-promo-background: #E8EAEA;// #a32921;
$color-notification-background: #717373;
$color-input-border: #717373;
$color-accent: #000000;//#a32921;

// ## Shades ##
$color-shade_1: $color-primary-background;
$color-shade_2: #efefef;
$color-shade_3: $color-primary;//#d8d8d8;
$color-shade_4: #CDCFCF;//#757575;
$color-shade_5: $color-primary;
$color-shade_6: #000000;
$color-shade_7: #000001;
$color-shade_8: #000002;
$color-shade_9: $color-primary;
$color-shade_10: #d7c727;

// ## GH/GF colors ##
$color-header_text: $color-primary;
$color-header_alt: $color-primary-background;
$color-footer_text: $color-primary;
$color-footer_alt: $color-primary;

$color-svg-icon: $color-secondary;


// ## Buttons ##

// ## Buttons ##
// ### Button Main ###
$color-button_main-background: $color-primary-background;
$color-button_main-border: $color-primary;// #a32921;
$color-button_main-text: $color-primary;
$color-button_main__hover-background: $color-primary;//#a32921;
$color-button_main__hover-border: $color-primary;// #a32921;
$color-button_main__hover-text: $color-shade_1;
$color-button_main__inversed-background: $color-primary;
$color-button_main__inversed-border: $color-primary;
$color-button_main__inversed-text: $color-primary;
$color-button_main__inversed__hover-background: $color-primary;// #a32921;
$color-button_main__inversed__hover-border: $color-primary;// #a32921;
$color-button_main__inversed__hover-text: $color-shade_1;
// ### Alt ###
$color-button_alt-background: $color-primary;
$color-button_alt-border:  $color-primary;//$color-primary;
$color-button_alt-text: $color-shade_1;
$color-button_alt__hover-background: $color-primary; //$color-primary;
$color-button_alt__hover-border: $color-primary;//$color-primary;
$color-button_alt__hover-text: $color-shade_1;
$color-button_alt__inversed-background: $color-primary-background;
$color-button_alt__inversed-border: $color-primary;
$color-button_alt__inversed-text: $color-primary;
$color-button_alt__inversed__hover-background: $color-primary-background;
$color-button_alt__inversed__hover-border: $color-primary-background;
$color-button_alt__inversed__hover-text: $color-shade_1;
// ## Badges ##
$color-badge-1: #d8d8d8;
$color-badge-2: #bfb9a1;
//============================================

// Semantic colors mapping to components
// # COMMON #
$color-text__dark: $color-primary-background;
$color-text__light: $color-primary;
$color-overlay-background: $color-shade_5;

// # COMPONENTS #
$color-product_image-background: transparent;//radial-gradient(circle, rgba($color-accent, 1) 0%, rgba($color-accent, 0) 50%);

// # PDP #
$color-product_gallery-thumb-border: transparent;// rgba($color-shade_5, 0.35);
$color-product_gallery-thumb__selected-border: #717373;//rgba($color-primary, 0.35);

// # PLP #
$color-filters-label: $color-input-border;

// ## MESSAGE ##
$color-message-text__inversed: $color-primary;

// ## Product tile ##
$color-product_tile-promo-background: $color-promo-background;
$color-product_tile-promo: $color-primary;
$color-quantity: #717373;

// ## PRODUCT BADGE ##
$color-badge_1-background: $color-shade_1;
$color-badge_1-color: $color-shade_3;
$color-badge_2-color: $color-badge-2;

// ## Header ##
$color-header-background: $color-shade_1;
$color-header_promo-background: $color-secondary;
$color-header__transparent-text: $color-header_text;
$color-header__transparent__inverted-text: $color-header_alt;
$color-header__transparent-background-border: rgba($color-shade_4, 0.15);
$color-header__transparent-header_promo-background: $color-promo-background;
$color-navigation_flyout-background: $color-primary-background;
$color-hamburger_menu-background: $color-primary-background;
$color-hamburger_back_link-background: $color-primary-background;
$color-hamburger_account-background: $color-primary-background;
$color-cookies-background: $color-shade_5;//??
$color-header_promo__transparent_header-background: $color-badge-2;//+
$color-main_nav-divider: $color-shade_5;//??
$color-mobile_navigation-categories_divider: $color-shade_5;//+
$color-mobile_navigation-section_divider: $color-shade_4;

// ## Footer ##
$color-footer-background: $color-primary-background transparent;
$color-footer-email_subscription-background: rgba($color-shade_6, 0.78);
$color-footer-border: $color-shade_4;

// ## NOTIFICATION ##
$color-notification-text: $color-primary-background;

// # ERROR PAGES #
$color-error_410-text: $color-text__light;

// # PAGE DESIGNER #
$color-banner_2_region: $color-primary;

// # PDP #
$color-product_gallery-thumbs_control-background: rgba($color-shade_6, 0.9);



// ## PROGRESS BAR ##
$color-progress-bg: transparent;
$color-progress-value-bg: $color-primary;

// ### ACCOUNT PANEL ###
$color-account_panel-background:$color-shade_1;

// ## CAROUSEL ##
$color-carousel_pagination_control: transparent;
$color-carousel_pagination_control__active: $color-primary;

// ## BACK TO TOP ##
$color-back_to_top-icon-background: $color-primary-background;

// ## MESSAGE ##
$color-message-background: $color-primary-background;
$color-message-border: $color-primary;
$color-message-text: $color-primary;
$color-message-text__inversed: $color-shade_1; //$color-primary-background ;
$color-message-background__inversed: rgba($color-primary, 0.15);


// ### SHIPPING STATES PANEL ###
$color-shipping_panel-background: $color-shade_1 ;
$color-shipping_panel__inverse-background: $color-primary ;
$color-browsing_only_note: $color-shade_3 ;
$color-state__hover-background: rgba($color-secondary,0.1);//$color-shade_5 ;
$color-shipping_panel-divider: $color-shade_4 ;
$color-shipping_panel_top-with_image: $color-primary-background ;

// ### MINICART PANEL ###
$color-minicart_panel-background: $color-shade_1;
$color-minicart_items-separator: rgba($color-shade_4, 0.8);

// ## ACCOUNT NAVIGATION ##
$color-account_nav-item__active-text: $color-primary;
$color-account_nav-item__active-bg: rgba($color-secondary,0.1);//$color-shade_5;


// ## BREADCRUMBS ##
$color-breadcrumb-current: $color-shade_3;
$color-breadcrumb-separator_bg: $color-shade_5;
$color-breadcrumb-link_color: $color-primary;



// ## FORMS ##
$color-form-input: $color-primary;
$color-form-input-background: $color-shade_1;//$color-primary-background !default;
$color-form-input-border: #717373;//$color-input-border !default;
$color-form-input__disabled: #3C4140;
$color-form-label: #3C4140;//$color-shade_3 !default;
$color-form-caption: #3C4140;//$color-shade_3 !default;

// # MY ACCOUNT #
$color-account_info_tile-bg: #717373;

// ## LINKS ##
$color-link__visited: $color-primary;
$color-link__disabled: $color-shade_4;

// # STORE LOCATOR #
$color-store_locator-close_text: $color-primary;
$color-store_locator-close_background: rgba($color-secondary,0.3);
$color-store-hover: #C1C6C9;

// # AGE GATE #
$color-age_gate-background: $color-primary-background;
$color-age_gate-text: $color-primary;

// # ERROR PAGES #

$color-error_410-text: $color-primary;
$color-error_404-text: $color-primary;
$color-error_page-background: $color-primary-background;
$color-error_page-text: $color-primary;

// # HEADER TAGS COLOR OVERRIDES #
$configuration-t_heading_1-color: $color-primary;
$configuration-t_heading_1-2-color: $color-primary;
$configuration-t_heading_2-color: $color-primary;
$configuration-t_heading_3-color: $color-primary;
$configuration-t_heading_4-color: $color-primary;
$configuration-t_heading_5-color: $color-primary;
$configuration-t_heading_6-color: $color-primary;

// # PARAGRAPH TAGS COLOR OVERRIDES #
$configuration-t_paragraph_1-color: $color-primary;
$configuration-t_paragraph_2-color: $color-primary;
$configuration-t_paragraph_3-color: $color-primary;
