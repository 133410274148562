/*md

# f-input_text

Default input element

## Default input

```scss_example
	.f-input_text {
		@include f-input_text($style: default);
	}
```

##Alt input

```scss_example
	.f-input_text {
		@include f-input_text($_mod: alt);
	}
```
*/

@mixin f-input_default($_mod: default, $el: root) {
	@if $el == root {
		@if $_mod == default {
			@include f-input_reset;
			border-radius: 28px;
			font: $configuration-form-input-font;
			height: $size-input-height;
			letter-spacing: 2.1px;
			line-height: 22px;
			transition: height linear 0.5s;

			@include media(sm) {
				height: $size-input-height-mobile;
			}
		}

		@if $_mod == focused {
			border-color: $color-form-input;
		}

		@if $_mod == invalid {
			color: $color-error;
		}

		@if $_mod == disabled {
			border-color: $color-form-input__disabled;
			color: $color-form-input__disabled;
		}
	}

	@if $el == label {
		@if $_mod == default {
			@include f-label(default);

			left: 15px;
			pointer-events: none;
			position: absolute;
			top: 14px;

			@include media(sm) {
				top: 19px;
			}
		}

		@if $_mod == alt {
			@include f-input_default($el: label);

			left: 0;
		}
	}
}

@mixin f-input_text($_mod: default) {
	@if $_mod == default {
		.f-input_text {
			position: relative;

			&-field {
				@include f-input_default(default);

				border: 1px solid $color-form-input-border;
				padding: 21px 40px 7px 15px;

				@include media(sm) {
					padding: 28px 40px 10px 15px;
				}

				&:hover {
					@include f-input_default(focused);
				}

				&.m-invalid {
					@include f-input_default(invalid);
				}

				&:disabled {
					@include f-input_default(disabled);
				}

				&:focus::placeholder {
					color: $color-form-label;
				}
			}

			&-label {
				@include f-input_default($el: label);
			}

			&.m-required &-label {
				@include f-label(required);
			}

			&-field:not(:placeholder-shown) ~ &-label,
			&-field:focus ~ &-label {
				transform: translateY(-40%) scale(0.75);
			}

			&-field:disabled ~ &-label {
				color: $color-form-input__disabled;
			}
		}
	}

	@if $_mod == alt {
		.f-input_text {
			position: relative;

			&-field {
				@include f-input_default(default);

				border: 1px solid $color-form-input-border;
				border-width: 0 0 1px;
				color: $color-form-input;
				padding-top: rh(3);

				&:hover {
					@include f-input_default(focused);
				}

				&.m-invalid {
					@include f-input_default(invalid);
				}

				&:disabled {
					@include f-input_default(disabled);
				}

				&:focus::placeholder {
					color: $color-form-label;
				}
			}

			&-label {
				@include f-input_default($el: label, $_mod: alt);
			}

			&.m-required &-label {
				@include f-label(required);
			}

			&-field:not(:placeholder-shown) ~ &-label,
			&-field:focus ~ &-label {
				transform: translateY(-40%) scale(0.75);
			}

			&-field:disabled ~ &-label {
				color: $color-form-input__disabled;
			}
		}
	}
}
