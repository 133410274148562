// stylelint-disable

@font-face {
    font-family: 'messerv2.1trialcondensed';
    src: url('./fonts/messerv2.1trial-condensed-webfont.woff2') format('woff2'),
         url('./fonts/messerv2.1trial-condensed-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'national_2_condensedregular';
    src: url('./fonts/national2condensed-regular-webfont.woff2') format('woff2'),
         url('./fonts/national2condensed-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'national_2medium';
    src: url('./fonts/national2-medium-webfont.woff2') format('woff2'),
         url('./fonts/national2-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'national_2regular';
    src: url('./fonts/national2-regular-webfont.woff2') format('woff2'),
         url('./fonts/national2-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


// stylelint-enable

//New Fonts
$font-main: 'messerv2.1trialcondensed',sans-serif;
$font-main-semiBold: 'national_2_condensedregular', sans-serif; 
$font-alt: 'national_2medium', serif;
$font-header: 'national_2regular', sans-serif;

